@import "~react-image-gallery/styles/scss/image-gallery.scss";
:root {
  --overflow-overlay: overlay;
}

/* Use for Firefox */
@supports not (overflow: overlay) {
  :root {
    --overflow-overlay: auto;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Epilogue", sans-serif;
}
*:not(svg) {
  box-sizing: border-box;
  overflow-anchor: auto;
}
p {
  margin-block-start: 0;
  margin-block-end: 0;
}
/* Scrollbar Style */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
}
::-webkit-scrollbar-thumb {
  background-color: #515b6f;
  box-shadow: none;
  backdrop-filter: blur(0.5px);
  border-radius: 25px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(81, 91, 111, 0.75);
}
::-webkit-scrollbar-thumb:active {
  background-color: rgba(81, 91, 111, 0.5);
  box-shadow: inset 1px 1px 2px #25324b;
}

/* Full height table cell */
th,
td {
  height: 0;
}
@-moz-document url-prefix() {
  tr,
  th,
  td {
    height: 100%;
  }
}

.recharts-tooltip-wrapper,
recharts-tooltip-wrapper * {
  &:focus-visible {
    outline: none !important;
  }
}
