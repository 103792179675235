.virtuoso__tableBody {
  tr td:first-child {
    z-index: 1;
  }

}

.virtuoso__tableBody.sticky {
  tr td:first-child {
    background-color: white !important;
  }
 
}

.virtuoso__tableHead {
  tr th:first-child {
    position: "-webkit-sticky";
    left: 0;
    z-index: 5;
  }

  tr th {
    transition: all 0.5s ease;
    top: 0;
  }
}

.virtuoso__tableHead.sticky {
  tr th {
    background-color: white !important;
  }

  .maProductNameHeaderCell {
    .productNameHeaderCell__Chip {
      top: 8px;
      left: 33px;
      width: 33px;
      height: 15px;
      & > .MuiChip-label {
        overflow: unset;
        font-size: 9px;
      }
    }

    .productStack {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      .productStack__productInfo {
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .productStack__Image {
          width: 33px;
          height: 33px;
          transition: all 0.5s ease;
        }

        .productStack__productName {
          text-align: left;
          padding-top: 0px;
          margin-top: 0px !important;
        }
      }

      .productStack__Overall {
        gap: 10px;
        flex-direction: row;

        .overall-text {
          line-height: 120%;
        }

        .overall-percent {
          line-height: 120%;
          font-size: 17px;
        }
      }
    }

    .buttonStack {
      .commentButton,
      .shortListButton {
        height: 20px;
      }
      .disqualifyButton,
      .rollbackButton {
        height: 20px;
        border-radius: 25px;
      }

      .rollbackSvg,
      .recycleBinSvg  {
        height: 12px;
        margin-right: 0px;
      }
    }
  }
  .bannerContentStack {
    svg,
    button,
    span {
      display: none;
    }
    height: 10px;
    margin-top: 5px;
  }
  
}
.virtuoso__tableContainer.sticky {
  margin-top: -45px;
}

.managedAccount__headerInfo.sticky {
  height: 0;
  span, div, button {
    display: none;
  }
}
