.sh-full-width-height {
  width: 100%;
  height: 100%;
}

.image-gallery {
  @extend .sh-full-width-height;
  .image-gallery-content {
    @extend .sh-full-width-height;
    .image-gallery-slide-wrapper {
      @extend .sh-full-width-height;
      .image-gallery-swipe {
        @extend .sh-full-width-height;
        .image-gallery-slides {
          @extend .sh-full-width-height;
          .image-gallery-slide {
            @extend .sh-full-width-height;
            img {
              @extend .sh-full-width-height;
              object-fit: contain;
              object-position: center center;
            }
          }
        }
      }
    }
  }
}
