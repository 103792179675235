.featureHeadContainer.sticky {
  height: 0;
  span, div, button {
    display: none;
  }
}

.featureTableContainer.sticky {
  margin-top: -30px;
}
